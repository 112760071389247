const vipPreferenceLang = {
  messages: {
    tw: {
      'student_preference': '學員喜愛',
    },
    en: {
      'student_preference': 'Student Preference',
    },
  },
};

export {
  vipPreferenceLang
};
