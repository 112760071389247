<template lang="pug">
div
  .row.justify-content-center
    b-alert(show variant='warning')
      span(v-html="vuecropperNotice")
  .row
    .col-sm-6
      .cut
        vue-cropper(ref='cropper' :img='cropperOption.img' :outputsize='1' :outputtype='cropperOption.outputType' @real-time='realTimeImage' :can-move='cropperOption.canMove' :can-move-box='cropperOption.canMoveBox' :fixed-box='cropperOption.fixedBox' :original='cropperOption.original' :auto-crop='true' :auto-crop-width='cropperOption.autoCropWidth' :auto-crop-height='cropperOption.autoCropHeight' :center-box='cropperOption.centerBox')
    .col-sm-6
      p.text-center.preview-text P r e v i e w
      #show-preview.show-preview
        img(:src="previews.url" :style="previews.img")
  .row.justify-content-center
    b-button(@click="handlePictureImagetoFile" :variant="confirm ? 'success' : 'outline-danger'" :disabled="confirm")
      span(v-if="!confirm") {{ $t('confirm') }}
      span(v-else-if="confirm")
        font-awesome-icon.text-light(icon="check")
    b-button.ml-3(v-if="confirm" @click="confirm = false" variant="outline-primary") {{ $t('reset') }}

</template>
<script>
import { VueCropper as vueCropper } from 'vue-cropper';

export default {
  name: 'ImageCropper',

  components: {
    vueCropper,
  },

  props: {
    // eslint-disable-next-line
    value: {
      type: [Array, String, File],
      default: function() {
        return [];
      },
    },

    fileName: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {
      previews: {},
      cropperOption: {
        img: '',
        full: false,
        outputType: 'png',
        canMove: true,
        fixedBox: true,
        original: true,
        canMoveBox: true,
        autoCrop: false,
        autoCropWidth: 270,
        autoCropHeight: 270,
        centerBox: false,
        high: true,
      },
      vuecropperNotice: '* Use the scroll wheel to zoom in and out.<br>* Move to adjust the photo.<br>* Click confirm.',
      confirm: false,
    };
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(file){
      if (file instanceof File){
        this.cropperOption.img = URL.createObjectURL(file);
      }
    },
  },

  methods: {
    realTimeImage(image) {
      this.previews = image;
    },
    handlePictureImagetoFile() {
      const fileName = `${this.fileName}.png`;
      this.$refs['cropper'].getCropData((data) => {
        const arr = data.split(','),
              bstr = atob(arr[1]);
        let length = bstr.length;
        const mime = arr[0].match(/:(.*?);/)[1],
              u8array = new Uint8Array(length);
        while (length--){
          u8array[length] = bstr.charCodeAt(length);
        }
        this.inputValue = new File([u8array], fileName, { type: mime });
        this.confirm = true;
      });
    },
  },
};
</script>
<style scoped>
.cut{border:3px solid #5B5B5B; width:320px; height:320px;}
.preview-text {color:#0B60B6; font-weight: 700;}
.show-preview {width: 275px; height: 275px; overflow:hidden; margin:20px;border-radius: 50%;}
.cropper-view-box {border-radius: 50%;}
@media only screen and (max-width: 700px) {
  .cut{
    margin: 0px auto;
  }

  .show-preview{
    margin: 5px auto;
  }
}

@media only screen and (max-width: 450px) {
  .cut{
    width:300px; height:300px;
  }

  .preview-text{
    margin-top: 15px;
  }

  .modal{
    padding: 0px !important;
  }
}
</style>
