const testNetSpeedLang = {
  messages: {
    tw: {
      'start_test_speed': '測試速度',
      'download': '下載',
      'upload': '上傳',
      'select_type_txt': '選擇類型',
      'time': '時間',
      'work': '辦公室',
      'home': '家裡',
      'other': '其他',
      'error_on_download': '下載錯誤',
      'error_on_upload': '上傳錯誤',
      'cancel': '取消',
      'ok': '確定',
    },
    en: {
      'start_test_speed': 'Start Test Speed',
      'download': 'Download',
      'upload': 'Upload',
      'select_type_txt': 'Select Type',
      'time': 'Time',
      'work': 'Work',
      'home': 'Home',
      'other': 'Other',
      'error_on_download': 'Error On Download',
      'error_on_upload': 'Error On Upload',
      'cancel': 'Cancel',
      'ok': 'OK',
    },
  },
};

export {
  testNetSpeedLang
};
