<template lang="pug">
  b-modal#remove_payment_modal(
    :cancel-title="'Cancle'"
    :ok-title="'OK'"
    :title="$t('remove_payment')"
    effect="fade"
    no-close-on-backdrop
    @ok="submit()"
    )
    span {{ $t('remove_confirm_mes') }}
    strong(v-if="payment.payment === 'taiwan_bank'") Taiwan's Bank "{{ payment.bank.code }} {{ payment.bank.chinese }} {{ payment.bank.english }}"<br>Bank Account : "{{ payment.bank_number }}"?
    strong(v-else-if="payment.payment === 'paypal'") Paypal "{{ payment.paypal_email }}" ?
</template>
<script>
import { mapState } from 'vuex';
import LioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from './lang';

export default {
  name: 'RemovePayment',

  i18n,

  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      payment: state => state.consultant.currentPaymentDatas,
    }),
  },

  mounted() {
  },

  methods: {
    async submit(){
      const removeResponse = await LioshutanApi.consultant.removeConsultantPayment({ id: this.payment.id, eb: 0 });
      if (removeResponse.data.message === 'OK'){
        alertMessage(this.$t('success'), 'success', this.$t('remove_up_success'));
        this.getconsultantPaymentDatas();
      }
    },

    async getconsultantPaymentDatas(){
      const consultantPaymentRes = await LioshutanApi.consultant.getConsultantPayment();
      this.$store.dispatch('consultant/setPaymentDatas', consultantPaymentRes.data.data);
    },
  },
};

</script>
