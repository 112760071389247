const ProfileDataLang = {
  messages: {
    tw: {
      'resume': '履歷',
    },
    en: {
      'my_profiles': 'My Profile',
      'check_other_month': 'Check other months',
      'identification_image_path': 'Legal Document Number (Taiwan ID/ARC/APRC) (Foreigners: driver\'s license/health card/passport) Image',
      'identification': 'Legal Document Number (Taiwan ID/ARC/APRC) (Foreigners: driver\'s license/health card/passport)',
      'your_identification_image': 'Your Identification Image',
      'your_identification_back_image': 'Your Identification Reverse Side Image',
      'username': 'Username',
      'gender': 'Gender',
      'email': 'E-Mail',
      'work_phone': 'Work Phone',
      'birthday': 'Birthday',
      'address': 'Local Address',
      'second_address': 'Second Address',
      'health_card': 'Health Card',
      'education': 'Education',
      'school_name': 'School Name',
      'major': 'Major',
      'nationality': 'Nationality',
      'occupation': 'Occupation',
      'preferred_topic': 'Preferred Topic\'s',
      'self_introduction': 'Self Introduction',
      'suggestion': 'Suggestion for English learning',
      'introduction_video': 'Introduction Video',
      'resume': 'Resume',
      'edit_profiles': 'Edit Profiles',
      'male': 'Male',
      'female': 'Female',
      'bachelor': 'Bachelor',
      'postgrad': 'Postgrad',
      'undergrad': 'Undergrad',
      'highSchool': 'High school',
    },
  },
};

export {
  ProfileDataLang
};
