<template lang="pug">
  b-form-group(:label-cols='labelCols' :label="`${need?'*':''} ${label}`")
    b-form-input(
      v-if="inputType === 'text' || inputType === 'password'"
      v-model="inputValue"
      :class="fileClass"
      :type="inputType"
      :placeholder="placeholder"
    )
    b-form-file(
      v-else-if="inputType === 'file'"
      v-model="inputValue"
      :accept='accept'
      :placeholder="placeholder")
</template>

<script>
import { getFiledInputedClass } from '@/utils/verify';

export default {
  name: 'InputField',
  props: {
    value: {
      type: [String, Number, Array, File],
      default: function() {
        return '';
      },
    },

    need: {
      type: [Boolean],
      default: function() {
        return false;
      },
    },

    label: {
      type: String,
      default: function() {
        return '';
      },
    },

    labelCols: {
      type: Number,
      default: function() {
        return 6;
      },
    },

    inputType: {
      type: String,
      default: function() {
        return 'text';
      },
    },

    placeholder: {
      type: String,
      default: function() {
        return '';
      },
    },

    accept: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  computed: {
    inputValue: {
      get() {
        // 如果是檔案類型就帶入空陣列，防止 b-form-file(只允許 Array & File)錯誤
        if (this.inputType === 'file' && typeof this.value === 'string') {
          return [];
        }
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    fileClass(){
      if (this.need){
        const status = this.value !== '';
        return getFiledInputedClass(status);
      } else {
        return '';
      }
    },
  },
};
</script>
