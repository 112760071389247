<template lang="pug">
li.list-group-item(v-if="value && value !== 'null' && value !== null")
  span.list-title-box {{ $t(title) }}
  span.float-right.list-content-box.text-secondary(v-html="value")
</template>

<script>
import { ProfileDataLang } from './../lang.js';

export default {
  name: 'InfoSpan',

  i18n: ProfileDataLang,

  props: {
    // eslint-disable-next-line
    title: {
      type: String,
      default: () => {
        return '';
      },
    },

    value: {
      type: [String, Number],
      default: () => {
        return '';
      },
    },
  },
};
</script>
<style scoped>
.list-content-box{
  word-break: break-all;
  line-height: 24px;
}
.list-title-box{
  font-size: 20px;
  font-weight: 800;
}
</style>
