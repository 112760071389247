export default {
  messages: {
    tw: {
      remove_certificate: '刪除證照',
      remove_confirm_mes: '確定要刪除證照',
      remove_up_success: '證照刪除成功',
      remove_up_fail: '證照刪除失敗',
    },
    en: {
      remove_certificate: 'Remove Certificate',
      remove_confirm_mes: 'Are you sure you want to remove ',
      remove_up_success: 'Remove certificate successfully',
      remove_up_fail: 'Remove certificate failed',
    },
  },
};
