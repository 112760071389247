<template lang="pug">
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title {{ $t('my_certificates') }}
    .wuwow-card-body
      table.table
        tbody
          tr
            th {{ $t('operations') }}
            th {{ $t('type') }}
            th {{ $t('name') }}
            th {{ $t('image') }}
          tr(v-for="(certificates, index) in consultantQualifications.slice(0, 20)" :key="'certificates-'+index")
            td
              a.btn-box-tool.mr-2(href='#' v-b-modal.remove_certificates_modal @click="setCurrentEditCertificates(certificates)")
                font-awesome-icon(icon="times")
              a.btn-box-tool(href='#' v-b-modal.edit_certificates_modal @click="setCurrentEditCertificates(certificates)")
                font-awesome-icon(icon="pen")
            td {{ $t(certificates.type) }}
            td {{ (certificates || {}).name }}
            td
              a(:href="certificates.cert_image_path" target="_blank")
                font-awesome-icon(icon="image")
      b-button.btn.btn-outline-primary.btn-block(v-b-modal.edit_certificates_modal  @click="setCurrentEditCertificates({})")
        b {{ $t("add_qualification") }}
      edit-certificates-modal(:certificates="currentEditCertificates" :key="'edit-'+currentEditCertificates.qualification_id")
      remove-certificates-modal(:certificates="currentEditCertificates" :key="'remove-'+currentEditCertificates.qualification_id")

</template>
<script>
import { mapState } from 'vuex';
import editCertificatesModal from './editCertificates';
import removeCertificatesModal from './removeCertificates';
import { certificatesLang } from './lang';
export default {
  name: 'Certificates',

  components: {
    editCertificatesModal,
    removeCertificatesModal,
  },

  i18n: certificatesLang,

  data() {
    return {
      currentEditCertificates: {},
    };
  },

  computed: {
    ...mapState({
      consultantQualifications: state => state.consultant.qualificationsDatas,
    }),
  },

  methods: {
    setCurrentEditCertificates(qualification){
      this.currentEditCertificates = qualification;
    },
  },
};
</script>
