<template lang="pug">
li.list-group-item(v-if="value && value !== 'null' && value !== null")
  b {{ $t(title) }}
  a.float-right(target='_blank' :href="value") {{ $t("view") }}
</template>

<script>
import { ProfileDataLang } from './../lang.js';

export default {
  name: 'InfoSpan',

  i18n: ProfileDataLang,

  props: {
    // eslint-disable-next-line
    title: {
      type: String,
      default: () => {
        return '';
      },
    },

    value: {
      type: [String, Number],
      default: () => {
        return '';
      },
    },
  },
};
</script>

