import { ProfileDataLang } from './../lang.js';

const tw = {
  'editProfiles': '編輯個人資料',
  'chineseName': '中文名字',
  'englishName': '英文名字',
  'pleaseInput': '請輸入',
  'oldPassword': '舊密碼',
  'newPassword': '新密碼',
  'confirmPassword': '密碼確認',
  'workPhone': '手機',
  'healthCard': '健保卡',
  'schoolName': '學校名稱',
  'selfIntroduction': '自我介紹',
  'learningSuggestion': '學習建議',
  'introductionVideo': '自我介紹影片',
  'idPictureFront': '身分證正面',
  'idPictureBack': '身分證反面',
  'resumeDoc': '你的履歷',
  'picture': '你的照片',
  'localAddress': '地址',
  'secondAddress': '地址2',
};

let en = {
  'editProfiles': 'Edit Profiles',
  'chineseName': 'Chinese Name',
  'englishName': 'English Name',
  'pleaseInput': 'Please input ',
  'oldPassword': 'Old Password',
  'newPassword': 'New Password',
  'confirmPassword': 'Confirm P.W.',
  'workPhone': 'Work Phone',
  'healthCard': 'Health Card',
  'schoolName': 'School Name',
  'selfIntroduction': 'Self Introduction',
  'learningSuggestion': 'Suggestion for English learning',
  'introductionVideo': 'Introduction Video',
  'idPictureFront': 'Your ID Card Picture',
  'idPictureBack': 'ID Card Back Picture',
  'resumeDoc': 'Your Resume',
  'picture': 'Your Picture',
  'localAddress': 'Local Address',
  'secondAddress': 'Second Address',
};

en = Object.assign(en, ProfileDataLang.messages.en);

const editProfileLang = {
  messages: {
    tw,
    en,
  },
};

export { editProfileLang };
