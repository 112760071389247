<template lang="pug">
  b-modal#test_net_speed_modal(
    :cancel-title="$t('cancel')"
    :ok-title="$t('ok')"
    :title="$t('start_test_speed')"
    effect="fade"
    no-close-on-backdrop
    @ok="submit"
    )
    div
      p Please make sure that no other applications are using your Internet connection!
      p * Don\'t close this windows or go back to home screen while testing.
      p * It\'s better to leave only this window open because the test will take up some resources of your device.
      p * The network\'s speed requirement is at least 4M upload and 4M download
    hr
    .container
      .row
        .col-xs-3 {{ $t("download") +"&nbsp;" }}
        .col-xs-9 {{ "&nbsp;" + getSpeedText(5) }}
      .row
        .col-xs-3 {{ $t("upload") +"&nbsp;" }}
        .col-xs-9 {{ "&nbsp;" + getSpeedText(10) }}
      .row
        .col-xs-12
          | {{ $t("select_type_txt") }}
          span.text-danger *
    b-form-select(:options='types')
    b-button(@click="startTest")
    div {{ $t("error_on_download") }}
    div {{ $t("error_on_upload") }}
</template>
<script>
import LioshutanApi from '@/api';
import { testNetSpeedLang } from './lang';
import Network from 'network-js/dist/network.min.js';
const network_settings = {
  endpoint: '/speed_test.php',
  upload: {
    delay: 8000,
    data: {
      size: 100 * 1024 * 1024,
      multiplier: 2,
    },
  },
  download: {
    delay: 4000,
    data: {
      size: 100 * 1024 * 1024,
      multiplier: 2,
    },
  },
};
export default {
  name: 'TestNetSpeed',

  i18n: testNetSpeedLang,

  data() {
    return {
      types: [this.$t('home'), this.$t('work'), this.$t('other')],
    };
  },

  mounted() {

  },

  methods: {
    submit(){
      this.$emit('closeTestNetSpeedModal', false);
    },

    startTest(){
      return this.runTest('upload').then((average_speed, allInstantSpeeds) => {
        if (average_speed) {
          this.data.trans_upload = this.convertBytesToMbs(average_speed);
          return Promise.resolve();
        } else {
          return Promise.reject('upload', average_speed, allInstantSpeeds);
        }
      }).then(() => {
        return this.runTest('download');
      }).then((average_speed, allInstantSpeeds) => {
        // console.log('[download] saved', average_speed);
        if (average_speed) {
          this.data.trans_download = this.convertBytesToMbs(average_speed);
          return Promise.resolve();
        } else {
          return Promise.reject('download', average_speed, allInstantSpeeds);
        }
      });
    },

    runTest: function(type) {
      // console.log('[' + type + '] started');
      return new Promise(function(resolve, reject) {
        const net = new Network(network_settings);
        net[type].on('end', function(average_speed, allInstantSpeeds) {
          if (average_speed === 'NaN') {
            // console.log('[' + type + '] rejected');
            reject(average_speed, allInstantSpeeds);
          } else {
            // console.log('[' + type + '] resolved');
            resolve(average_speed);
          }
        }).start();
        setTimeout(function() {
          net[type].abort();
        }, 5000);
      });
    },

    getSpeedText: function(speed) {
      return speed + ' Mbps';
    },

    convertBytesToMbs: function(bytes) {
      return ((bytes * 8) / (1024 * 1024)).toFixed(2);
    },

    async getLocationsSpeedData(){
      // 取得資料並用vuex管理同page資料 (1.方便子層直接取得資料 2.編輯及新增後資料刷新)
      const consultantLocationsSpeedRes = await LioshutanApi.consultant.getConsultantLocationsSpeed();
      this.$store.dispatch('consultant/setLocationsSpeedDatas', consultantLocationsSpeedRes.data);
    },
  },
};

</script>

<style></style>
