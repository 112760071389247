export default {
  messages: {
    tw: {
      remove_device: '刪除設備',
      remove_confirm_mes: '確定要刪除設備',
      remove_up_success: '設備刪除成功',
      remove_up_fail: '設備刪除失敗',
    },
    en: {
      remove_device: 'Remove Device',
      remove_confirm_mes: 'Are you sure you want to remove ',
      remove_up_success: 'Remove device successfully',
      remove_up_fail: 'Remove device failed',
    },
  },
};
