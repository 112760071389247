<template lang="pug">
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title {{ $t('my_devices') }}
    .wuwow-card-body
      table.table
        tbody
          tr
            th {{ $t("operations") }}
            th {{ $t("type") }}
            th {{ $t("device") }}
            th {{ $t("os") }}
            th {{ $t("browser") }}
          tr(v-for="(device, index) in consultantDevices.slice(0, 20)" :key="'device-'+index")
            td
              a.btn-box-tool.mr-2(href="#" v-b-modal.remove_device_modal @click="setCurrentEditDevice(device)")
                font-awesome-icon(icon="times")
              a.btn-box-tool(href="#" v-b-modal.edit_device_modal @click="setCurrentEditDevice(device)")
                font-awesome-icon(icon="pen")
            td
              font-awesome-icon(:icon="device.type")
            td {{ device.brand }} {{ device.model }}
            td {{ device.os_family }} {{ device.os_version }}
            td {{ device.browser }} {{ device.browser_version }}
      b-button.btn.btn-outline-primary.btn-block(v-b-modal.edit_device_modal @click="setCurrentEditDevice({})")
        b {{ $t("add_device") }}
      edit-device-modal(:device="currentEditDevice" :key="'Edit-' + currentEditDevice.device_id")
      remove-device-modal(:device="currentEditDevice" :key="'Remove-' + currentEditDevice.device_id")
</template>
<script>
import { mapState } from 'vuex';
import EditDeviceModal from './editDevice';
import RemoveDeviceModal from './removeDevice';
import { deviceLang } from './lang';
export default {
  name: 'Device',

  components: {
    EditDeviceModal,
    RemoveDeviceModal,
  },

  i18n: deviceLang,

  data() {
    return {
      currentEditDevice: {},
    };
  },

  computed: {
    ...mapState({
      consultantDevices: state => state.consultant.devicesDatas,
    }),
  },

  methods: {
    getDeviceTypeIcon(type){
      if (type === 'desktop' || type === 'laptop' || type === 'mobile'){
        return type;
      } else if (type === 'tablet'){
        return type + '-alt';
      } else {
        return null;
      }
    },

    setCurrentEditDevice(device){
      this.currentEditDevice = device;
    },
  },
};
</script>

<style></style>
