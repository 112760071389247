<template lang="pug">
  b-modal#edit-porfile-modal(
    :ok-title="$t('submit')"
    :title="$t('edit_profiles')"
    size="lg"
    effect="fade"
    no-close-on-backdrop
    @hide="hideEditProfileModal()"
    @ok="submit()"
    )
    .row
      .col-sm-6
        inputFiled(v-model="editProfileData.englishName" :labelCols="4" :label="$t('englishName')" :need="true")
      .col-sm-6
        inputFiled(v-model="editProfileData.chineseName" :labelCols="4" :label="$t('chineseName')" :need="true")
    .row
      .col-sm-6
        b-form-group(:label-cols='4' :label="`* ${$t('gender')}`")
          b-form-radio-group(
            v-model="editProfileData.gender"
            :options="genderOptions"
            buttons
            button-variant="outline-primary"
          )
      .col-sm-6
        b-form-group(:label-cols='4' :label="`* ${$t('birthday')}`")
          datepicker(
            v-model="editProfileData.birthday"
            :bootstrap-styling='true'
            format='yyyy-MM-dd'
          )
    .row
      .col-sm-6
        inputFiled(v-model="editProfileData.newPassword" :labelCols="4" :label="$t('newPassword')" :inputType='`password`' :need="false")
      .col-sm-6
        inputFiled(v-model="editProfileData.email" :labelCols="4" :label="$t('email')" :need="true")
    .row
      .col-sm-6
        inputFiled(v-model="editProfileData.confirmPassword" :labelCols="4" :label="$t('confirmPassword')" :inputType='`password`' :need="false")
      .col-sm-6
        inputFiled(v-model="editProfileData.workPhone" :labelCols="4" :label="$t('workPhone')" :need="true")
    .row
      .col-sm-6
        b-form-group(:label-cols='5' :label="$t('healthCard')")
          b-form-checkbox.health-card-checkbox.mt-2(
            v-model="editProfileData.healthCard"
            :value="1"
            :unchecked-value="0"
          )
          span.health-card-checkbox-label Yes
    .row
      .col-sm-12
        inputFiled(v-model="editProfileData.identification" :labelCols="6" :label="$t('identification')" :need="true")
    .row
      .col-sm-12
        inputFiled(v-model="editProfileData.localAddress" :labelCols="2" :label="$t('localAddress')" :need="true")
      .col-sm-12
        inputFiled(v-model="editProfileData.secondAddress" :labelCols="2" :label="$t('secondAddress')" :need="false")
    hr
    br
    .row
      .col-sm-6
        b-form-group(:label-cols='4' :label="`* ${$t('nationality')}`")
          b-form-select(
            v-model="editProfileData.nationality"
            :options="nationalitiesOptions"
            :class="getFiledInputedClass(checkEditProfileFiledInputed(`nationality`))"
          )
      .col-sm-6
        inputFiled(v-model="editProfileData.occupation" :labelCols="4" :label="$t('occupation')" :need="true")
    .row
      .col-sm-6
        b-form-group(:label-cols='4' :label="`* ${$t('education')}`")
          b-form-select(
            v-model="editProfileData.education"
            :options="teacherEducationsOptions"
            :class="getFiledInputedClass(checkEditProfileFiledInputed(`education`))"
          )
      .col-sm-6
        inputFiled(v-model="editProfileData.schoolName" :labelCols="4" :label="$t('schoolName')" :need="true")
    .row
      .col-sm-6
        inputFiled(v-model="editProfileData.major" :labelCols="4" :label="$t('major')" :need="true")
    .row
      .col-sm-6
        b-form-group(:label-cols='4' :label="`* ${$t('selfIntroduction')}`")
          b-form-textarea(
            v-model="editProfileData.selfIntroduction"
            :placeholder='`At least ${introductionContentLimit} characters`'
            :class="getFiledInputedClass(checkEditProfileFiledInputed(`selfIntroduction`) && verifySelfIntroduction)"
            :rows='3'
            :max-rows='6'
          )
          div(v-show="!verifySelfIntroduction")
            div.text-danger {{`*Need to be filled at least ${introductionContentLimit} characters.`}}
      .col-sm-6
        b-form-group(:label-cols='4' :label="`* ${$t('learningSuggestion')}`")
          b-form-textarea(
            v-model="editProfileData.learningSuggestion"
            :class="getFiledInputedClass(checkEditProfileFiledInputed(`learningSuggestion`) && verifyLearningSuggestion)"
            :rows='3'
            :max-rows='6'
            :placeholder='`At least ${introductionContentLimit} characters`'
          )
          div(v-show="!verifyLearningSuggestion")
            div.text-danger {{`*Need to be filled at least ${introductionContentLimit} characters.`}}
    hr
    br
    .row
      .col-sm-12
        inputFiled(
          v-model="editProfileData.introductionVideo"
          :labelCols="3"
          :label="$t('introductionVideo')"
          :placeholder="`Please attatch youtube video link`"
          :need="true"
        )
    .row
      .col-sm-6
        inputFiled(v-model="editProfileData.idPictureFront" :labelCols="6" :label="`* ${$t('idPictureFront')}`" :inputType='`file`' :accept='allowPictureFormat' :placeholder="!checkFileIsAllow(editProfileData.idPictureFront) ? 'Empty...' : 'Uploaded'")
        span.file-notice-text.text-danger(v-if="!checkFileIsAllow(editProfileData.idPictureFront)" v-html="imageFileNotice")
        span.file-notice-text.text-success(v-else)
          font-awesome-icon(icon="check")
      .col-sm-6
        inputFiled(v-model="editProfileData.idPictureBack" :labelCols="6" :label="`* ${$t('idPictureBack')}`" :inputType='`file`' :accept='allowPictureFormat' :placeholder="!checkFileIsAllow(editProfileData.idPictureBack) ? 'Empty...' : 'Uploaded'")
        span.file-notice-text.text-danger(v-if="!checkFileIsAllow(editProfileData.idPictureBack)" v-html="imageFileNotice")
        span.file-notice-text.text-success(v-else)
          font-awesome-icon(icon="check")
    .row
      .col-sm-6
        inputFiled(v-model="editProfileData.resumeDoc" :labelCols="6" :label="`* ${$t('resumeDoc')}`" :inputType='`file`' :accept='allowDocumentFormat' :placeholder="!checkFileIsAllow(editProfileData.resumeDoc) ? 'Empty...' : 'Uploaded'")
        span.file-notice-text.text-danger(v-if="!checkFileIsAllow(editProfileData.resumeDoc)" v-html="documentFileNotice")
        span.file-notice-text.text-success(v-else)
          font-awesome-icon(icon="check")
      .col-sm-6
        inputFiled(ref="picture" v-model="editProfileData.picture" :labelCols="6" :label="`* ${$t('picture')}`" :inputType='`file`' :accept='allowPictureFormat' :placeholder="!checkFileIsAllow(editProfileData.picture) ? 'Empty...' : 'Uploaded'")
        span.file-notice-text.text-danger(v-if="!checkFileIsAllow(editProfileData.picture)" v-html="imageFileNotice")
        span.file-notice-text.text-success(v-else)
          font-awesome-icon(icon="check")
    .row.justify-content-md-center(v-show="showAdjustPicture")
      image-cropper(v-model="editProfileData.picture" :fileName="this.editProfileData.englishName")
    hr
</template>
<script>
import datepicker from 'vuejs-datepicker';
import vueMultiselect from 'vue-multiselect';
import { editProfileLang } from '@/components/consultant/profile/profileData/editPorfile/lang';
import commonContants from '@/constants/common';
import httpCodeContants from '@/constants/httpCode';
import nationalities from '@/components/consultant/profile/profileData/editPorfile/nationalities';
import inputFiled from '@/components/consultant/profile/profileData/editPorfile/inputFileds/index.vue';
import imageCropper from '@/components/consultant/profile/profileData/editPorfile/imageCropper/index.vue';
import { checkEmailFormat, getFiledInputedClass, checkValidURLFormat, passwordChangeCheck } from '@/utils/verify';
import { alertHtmlMessage, alertFailedMessage, alertSuccessMessage } from '@/utils/sweetAlert';
import { setParams } from '@/utils/index.js';
import { getDate } from '@/utils/moment.js';
import lioshutanApi from '@/api';

const fileMaxSize = 2097152;
export default {
  name: 'EditProfile',

  components: {
    datepicker,
    multiselect: vueMultiselect,
    inputFiled,
    imageCropper,
  },

  props: {
    // eslint-disable-next-line
    show_edit_profile_modal: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    consultantBaseProfile: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n: editProfileLang,

  data() {
    return {
      previews: {},
      cropperOption: {
        img: '',
        full: false,
        outputType: 'png',
        canMove: true,
        fixedBox: true,
        original: true,
        canMoveBox: true,
        autoCrop: false,
        autoCropWidth: 270,
        autoCropHeight: 270,
        centerBox: false,
        high: true,
      },
      editProfileData: {
        englishName: '',
        chineseName: '',
        gender: '',
        birthday: '',
        newPassword: '',
        confirmPassword: '',
        email: '',
        workPhone: '',
        healthCard: '',
        identification: '',
        localAddress: '',
        secondAddress: '',
        nationality: '',
        occupation: '',
        education: '',
        schoolName: '',
        major: '',
        selfIntroduction: '',
        learningSuggestion: '',
        introductionVideo: '',
        idPictureFront: [],
        idPictureBack: [],
        resumeDoc: [],
        picture: [],
      },
      requireFileds: [
        'englishName',
        'chineseName',
        'gender',
        'birthday',
        'email',
        'workPhone',
        'identification',
        'localAddress',
        'nationality',
        'occupation',
        'education',
        'schoolName',
        'major',
        'selfIntroduction',
        'learningSuggestion',
        'introductionVideo',
        'idPictureFront',
        'idPictureBack',
        'resumeDoc',
        'picture',
      ],
      introductionContentLimit: 200,
      allowPictureFormat: 'image/png, image/jpeg, image/jpg',
      allowDocumentFormat: '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      imageFileNotice: '1. Max Size: 2MB <br> 2. File Format: jpeg, jpg, png',
      documentFileNotice: '1. Max Size: 2MB <br> 2. File Format: doc, docx, ppt, pptx, pdf',
      vuecropperNotice: '* Use the scroll wheel to zoom in and out.<br>* Move to adjust the photo.',
      genderOptions: [
        { text: this.$t(commonContants.GENDER.MALE), value: 'M' },
        { text: this.$t(commonContants.GENDER.FEMALE), value: 'F' },
      ],
      teacherEducationsOptions: [
        { text: this.$t(commonContants.EDUCATION.BACHELOR), value: commonContants.EDUCATION.BACHELOR },
        { text: this.$t(commonContants.EDUCATION.MASTER), value: commonContants.EDUCATION.MASTER },
        { text: this.$t(commonContants.EDUCATION.DOCTOR), value: commonContants.EDUCATION.DOCTOR },
      ],
      nationalitiesOptions: nationalities,
    };
  },

  computed: {
    checkEmailFormat(){
      return checkEmailFormat(this.editProfileData.email);
    },
    checkValidURLFormat(){
      return checkValidURLFormat(this.editProfileData.introductionVideo);
    },
    showAdjustPicture(){
      return this.editProfileData.picture !== null && this.editProfileData.picture.size < fileMaxSize;
    },
    verifySelfIntroduction(){
      if (this.editProfileData.selfIntroduction !== null){
        return this.editProfileData.selfIntroduction.length >= this.introductionContentLimit;
      }
      return false;
    },
    verifyLearningSuggestion(){
      if (this.editProfileData.learningSuggestion !== null){
        return this.editProfileData.learningSuggestion.length >= this.introductionContentLimit;
      }
      return false;
    },
  },

  watch: {
    consultantBaseProfile(value){
      const editProfileKeys = {
        english_name: 'englishName',
        chinese_name: 'chineseName',
        gender: 'gender',
        birthday: 'birthday',
        email: 'email',
        work_phone: 'workPhone',
        health_card: 'healthCard',
        identification: 'identification',
        address: 'localAddress',
        second_address: 'secondAddress',
        nationality: 'nationality',
        occupation: 'occupation',
        education: 'education',
        school_name: 'schoolName',
        major: 'major',
        self_introduction: 'selfIntroduction',
        suggestion: 'learningSuggestion',
        introduction_video: 'introductionVideo',
        identification_image_path: 'idPictureFront',
        identification_back_image_path: 'idPictureBack',
        resume: 'resumeDoc',
        picture: 'picture',
      };
      const newFormatData = setParams(editProfileKeys, value);
      this.editProfileData = newFormatData;
    },
  },

  methods: {
    hideEditProfileModal(){
      this.$emit('closeEditProfileModal', false);
    },
    checkEditProfileFiledInputed(key){
      return this.editProfileData[key] !== '' && this.editProfileData[key] !== null;
    },
    checkFileIsAllow(file){
      if (file !== null){
        return checkValidURLFormat(file) || file.size < fileMaxSize;
      }
      return false;
    },
    passwordChangeCheck(){
      return passwordChangeCheck(this.editProfileData.newPassword, this.editProfileData.confirmPassword);
    },

    getFiledInputedClass(checked){
      return getFiledInputedClass(checked);
    },
    paramsDataHandel(){
      this.editProfileData.birthday = getDate(this.editProfileData.birthday);
    },
    checkAllRequireFiled(requireFileds, ProfileData){
      const emptyFileds = [];
      requireFileds.forEach((field) => {
        if (ProfileData[field] === null || ProfileData[field].length === 0){
          emptyFileds.push(field);
        }
      });
      return emptyFileds;
    },
    getEmptyFieldsMessage(emptyFileds){
      let emptyFieldsMessage = '';
      emptyFileds.forEach((field) => {
        emptyFieldsMessage += `${this.$t(field)}<br>`;
      });
      alertHtmlMessage('Require Fields', 'error', emptyFieldsMessage, 'OK');
    },
    async fieldFormatCheckMessage(){
      if (!this.checkEmailFormat){
        await alertFailedMessage('Please check your email format.');
        return false;
      }
      if (!this.checkValidURLFormat){
        await alertFailedMessage('Please check your introduction video format.');
        return false;
      }
      if (!this.verifySelfIntroduction){
        await alertFailedMessage('Please check self introduction characters');
        return false;
      }
      if (!this.verifyLearningSuggestion){
        await alertFailedMessage('Please check learning suggestion characters');
        return false;
      }
      return true;
    },
    async submit(){
      this.paramsDataHandel();
      const emptyFields = this.checkAllRequireFiled(this.requireFileds, this.editProfileData);
      if (emptyFields.length > 0){
        this.getEmptyFieldsMessage(emptyFields);
      } else if (this.fieldFormatCheckMessage() && this.passwordChangeCheck()) {
        try {
          const result = await lioshutanApi.consultant.updateConsultantProfile(this.editProfileData);
          if (result.status === httpCodeContants.SUCCESS){
            const consultantProfileRes = await lioshutanApi.consultant.getConsultantProfile();
            this.$store.dispatch('consultant/setBaseProfileDatas', consultantProfileRes.data.data.user);
            await alertSuccessMessage('Uploaded Successfully');
          }
        } catch (error) {
          const firstIndex = 0;
          const responseErrorMessage = error.response.data.message;
          let errorMessage = '';
          Object.keys(responseErrorMessage).forEach((message) => {
            errorMessage += `${responseErrorMessage[message][firstIndex]}<br>`;
          });
          alertHtmlMessage('Uploaded Failed!!', 'error', errorMessage, 'OK');
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "./editProfile";
.health-card-checkbox{
  position: relative;
  left: -55px;
  width: 10px;
  margin: 0px;
  display: inline-block;
}
.health-card-checkbox-label{
  margin-left: -30px;
}
.file-notice-text{
  position: relative;
  top: -20px;
}
</style>
