
<template lang="pug">
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title {{ $t('my_internet_speeds') }}
    .wuwow-card-body
      table.table
        tbody
          tr
            th {{ $t("type") }}
            th {{ $t("download") }}
            th {{ $t("upload") }}
            th {{ $t("time") }}
          tr
          tr( v-for="(locationsSpeed, index) in consultantLocationsSpeed.slice(0, 5)" :key="index")
            td
              template
                font-awesome-icon(:icon="getLocationIcon(locationsSpeed.description)")
                |  {{ $t(locationsSpeed.description) }}
            td(:class="isTooSlow(locationsSpeed.trans_upload, 6)") {{ locationsSpeed.trans_download }} Mbps
            td(:class="isTooSlow(locationsSpeed.trans_upload, 2)") {{ locationsSpeed.trans_upload }} Mbps
            td {{ calCreatedTime(locationsSpeed.created_at) }}
      b-button.btn.btn-outline-primary.btn-block(v-b-modal.test_net_speed_modal)
        b {{ $t("start_test") }}
      test-net-speed-modal
</template>
<script>
import { mapState } from 'vuex';
import TestNetSpeedModal from './testNetSpeed';
import { internetSpeedLang } from './lang';
export default {
  name: 'InternetSpeed',

  components: {
    TestNetSpeedModal,
  },

  i18n: internetSpeedLang,

  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      consultantLocationsSpeed: state => state.consultant.locationsSpeedDatas,
    }),
  },
  mounted(){
  },

  methods: {
    getLocationIcon(description){
      if (description === 'work'){
        return 'briefcase';
      } else if (description === 'home'){
        return 'home';
      } else if (description === 'other'){
        return 'question';
      }
    },

    isTooSlow: function(speed, suggested) {
      return speed > suggested ? 'bg-success' : 'bg-danger';
    },

    calCreatedTime(date){
      const createTime = date.substring(0, 19);
      return createTime;
    },
  },
};
</script>

<style></style>
