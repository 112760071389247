var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "remove_payment_modal",
      "cancel-title": 'Cancle',
      "ok-title": 'OK',
      "title": _vm.$t('remove_payment'),
      "effect": "fade",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        return _vm.submit();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('remove_confirm_mes')))]), _vm.payment.payment === 'taiwan_bank' ? _c('strong', [_vm._v("Taiwan's Bank \"" + _vm._s(_vm.payment.bank.code) + " " + _vm._s(_vm.payment.bank.chinese) + " " + _vm._s(_vm.payment.bank.english) + "\""), _c('br'), _vm._v("Bank Account : \"" + _vm._s(_vm.payment.bank_number) + "\"?")]) : _vm.payment.payment === 'paypal' ? _c('strong', [_vm._v("Paypal \"" + _vm._s(_vm.payment.paypal_email) + "\" ?")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }