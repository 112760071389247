var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "edit_payment_modal",
      "cancel-title": _vm.$t('cancel'),
      "ok-title": _vm.$t('ok'),
      "title": _vm.isAddMode ? _vm.$t('add_payment') : _vm.$t('edit_payment'),
      "effect": "fade",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.submit
    }
  }, [_c('b-form-group', {
    attrs: {
      "horizontal": "",
      "label-cols": 6,
      "breakpoint": "sm",
      "label": _vm.$t('payment_method'),
      "label-for": "ddlPayment"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlPayment",
      "name": "payment",
      "options": _vm.payment_options
    },
    model: {
      value: _vm.paymentData.payment,
      callback: function ($$v) {
        _vm.$set(_vm.paymentData, "payment", $$v);
      },
      expression: "paymentData.payment"
    }
  })], 1), _vm.paymentData.payment === 'taiwan_bank' ? _c('taiwan-bank', {
    key: 'taiwan-bank-' + _vm.paymentData.id,
    ref: "taiwan_bank",
    attrs: {
      "isAddMode": _vm.isAddMode
    }
  }) : _vm.paymentData.payment === 'paypal' ? _c('paypal', {
    key: 'paypal' + _vm.paymentData.id,
    ref: "paypal",
    attrs: {
      "isAddMode": _vm.isAddMode
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }