<template lang="pug">
li.list-group-item
  b {{ $t("identification_image_path") }}
  br
  .row
    .col-sm-6
      p(align='center') Front
      a(target='_blank')
        b-img(v-if="consultantBaseProfile.identification_image_path" :src="consultantBaseProfile.identification_image_path" center alt='Your Identification Image' style='width:50%')
        span.text-danger(v-else) {{ $t("please")+' '+$t("upload")+' '+$t("your_identification_image")}}
    .col-sm-6
      p(align='center') Back
      a(target='_blank')
        b-img(v-if="consultantBaseProfile.identification_back_image_path" :src="consultantBaseProfile.identification_back_image_path" center alt='Your Identification Back Image' style='width:50%')
        span.text-danger(v-else) {{ $t("please")+' '+$t("upload")+' '+$t("your_identification_back_image")}}
  br
  h3.profile-username.text-center {{ $t("your_identification_image") }}
  b {{ $t("identification") }}
  span.float-right {{ consultantBaseProfile.identification }}
</template>

<script>
import { ProfileDataLang } from './../lang.js';

export default {
  name: 'InfoSpan',

  i18n: ProfileDataLang,

  props: {
    // eslint-disable-next-line
    consultantBaseProfile: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

