<template lang="pug">
  b-modal#edit_device_modal(
    :cancel-title="$t('cancel')"
    :ok-title="$t('ok')"
    :title="isAddMode ? $t('add_device') : $t('edit_device')"
    effect="fade"
    no-close-on-backdrop
    @ok="submit()"
    )
    .row
      .col-sm-6
        b-form-group(:label-cols='4' breakpoint='sm' :label="$t('type')" label-for='ddlType')
          b-form-select#ddlType(v-model='deviceData.type' :options='types')
    .row
      .col-sm-6
        b-form-group(:label-cols='4' breakpoint='sm' :label="$t('brand')" label-for='ddlBrand')
          b-form-select#ddlBrand(v-model='deviceData.brand' :options='brands')
      .col-sm-6
        b-form-group(:label-cols='4' breakpoint='sm' :label="$t('model')" label-for='txtModel')
          b-form-input#txtModel(v-model='deviceData.model' name='model' :placeholder="$t('please_input') +' '+ $t('model')")
    .row
      .col-sm-6
        b-form-group(:label-cols='4' breakpoint='sm' :label="$t('os')" label-for='ddlOsFamily')
          b-form-select#ddlOsFamily(v-model='deviceData.os_family' :options='systems')
      .col-sm-6
        b-form-group(:label-cols='4' breakpoint='sm' :label="$t('os_version')" label-for='txtOsVersion')
          b-form-input#txtOsVersion(v-model='deviceData.os_version' name='osVersion' :placeholder="$t('please_input') +' '+ $t('os_version')")
    .row
      .col-sm-6
        b-form-group(:label-cols='4' breakpoint='sm' :label="$t('browser')" label-for='ddlBrowser')
          b-form-select#ddlBrowser(v-model='deviceData.browser' :options='browsers')
      .col-sm-6
        b-form-group(:label-cols='4' breakpoint='sm' :label="$t('browser_version')" label-for='txtBrowserVersion')
          b-form-input#txtBrowserVersion(v-model='deviceData.browser_version' name='browserVersion')

</template>
<script>
import lioshutanApi from '@/api';
import platform from 'platform';
import { alertMessage } from '@/utils/sweetAlert.js';
import { addDeviceLang } from './lang';
export default {
  name: 'EditDevice',

  i18n: addDeviceLang,

  props: {
    // eslint-disable-next-line
    device: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      isAddMode: !Object.prototype.hasOwnProperty.call(this.device, 'device_id'),
      // All modifications made when adding or editing will be saved here temporarily.
      deviceData: {
        type: 'desktop',
        brand: '',
        model: '-',
        os_family: '',
        os_version: '',
        browser: '',
        browser_version: '',
        computer_image_path: null,
        os_screen_path: null,
        enabled: 1,
      },

      types: ['desktop', 'laptop', 'mobile', 'tablet'],
      systems: ['android', 'ios', 'macos', 'windows'],
      brands: ['Acer', 'Alienware', 'Apple', 'Asus', 'CJScope', 'Dell', 'Fujitsu', 'Gigabytes', 'Google', 'HP', 'HTC', 'Huawei', 'Infocus', 'Lenovo', 'LG', 'Microsoft', 'Motorola', 'MSI', 'Nokia', 'Oppo', 'Panasonic', 'Samsung', 'Sharp', 'Sony', 'Toshiba', 'Xiaomi', 'Other'],
      browsers: ['Chrome', 'Firefox', 'IE', 'Microsoft Edge', 'Opera', 'Safari', 'Vivaldi', 'Other'],
    };
  },

  mounted() {
    this.setDefaultDeviceData();
  },

  methods: {
    submit(){
      if (this.isAddMode){
        this.addDevice();
      } else {
        this.editDevice();
      }
    },

    async addDevice(){
      try {
        const addResponse = await lioshutanApi.consultant.addConsultantDevice(this.deviceData);
        if (addResponse.data.message === 'OK'){
          this.setEventSuccess();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('set_up_fail'));
      }
    },

    async editDevice(){
      try {
        const editResponse = await lioshutanApi.consultant.editConsultantDevice(this.deviceData);
        if (editResponse.data.message === 'OK'){
          this.setEventSuccess();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('set_up_fail'));
      }
    },

    setEventSuccess(){
      alertMessage(this.$t('success'), 'success', this.$t('set_up_success'));
      this.getDeviceData();
    },

    async getDeviceData(){
      // 取得資料並用vuex管理同page資料 (1.方便子層直接取得資料 2.編輯及新增後資料刷新)
      const consultantDevicesRes = await lioshutanApi.consultant.getConsultantDevices();
      this.$store.dispatch('consultant/setDevicesDatas', consultantDevicesRes.data.data);
    },

    setDefaultDeviceData(){
      if (this.isAddMode){
        this.deviceData = {
          type: 'desktop',
          brand: platform.manufacturer || 'Acer',
          model: platform.product || '-',
          os_family: this.getOs(),
          os_version: platform.os.version,
          browser: platform.name,
          browser_version: platform.version,
          computer_image_path: null,
          os_screen_path: null,
          enabled: 1,
        };
      } else {
        this.deviceData = this.device;
      }
    },

    getOs(){
      const os = platform.os.family;
      if (os.indexOf('Windows') !== -1){
        return 'windows';
      } else if (os.indexOf('OS X') !== -1){
        return 'macos';
      } else if (os.indexOf('Android') !== -1){
        return 'android';
      } else if (os.indexOf('iOS') !== -1){
        return 'ios';
      }
    },
  },
};

</script>
