<template lang="pug">
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title {{ $t('my_payment_method') }}
    .wuwow-card-body
      .col-xs-12.table-responsive.vuetable-overscroll
        h3.text-center
          b-badge(variant='danger') {{ paymentAlertMes() }}
        div(v-for="(payment, index) in consultantPayment" :key="'payment-'+index")
          taiwan-bank-table( v-if="payment.payment === 'taiwan_bank'" :payment="payment")
          paypal-table( v-if="payment.payment === 'paypal'" :payment="payment")
        br
        b-button.btn.btn-outline-primary.btn-block(v-b-modal.edit_payment_modal @click="setCurrentPayment({})")
          b {{ $t("create_payment") }}
        edit-payment
        remove-payment
</template>
<script>
import { mapState } from 'vuex';
import LioshutanApi from '@/api';
import EditPayment from './editPayment';
import RemovePayment from './removePayment';
import TaiwanBankTable from './taiwanBankTable';
import PaypalTable from './paypalTable';
import { paymentLang } from './lang';
export default {
  name: 'Payment',

  components: {
    EditPayment,
    RemovePayment,
    TaiwanBankTable,
    PaypalTable,
  },

  i18n: paymentLang,

  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      consultantPayment: state => state.consultant.paymentDatas,
    }),
  },

  mounted(){
    this.getBankCode();
  },

  methods: {

    async getBankCode(){
      const res = await LioshutanApi.common.getBankCode();
      this.$store.dispatch('common/setBankOptions', res.data);
    },

    paymentAlertMes(){
      if (this.consultantPayment.length === 0){
        return this.$t('create_payment_mes');
      } else if (this.consultantPayment.length > 0 && this.getDefaultPayment()){
        return this.$t('select_payment_mes');
      } else {
        return '';
      }
    },

    getDefaultPayment(){
      try {
        this.consultantPayment.forEach((element) => {
          if (element.default_payment === 1){
            // eslint-disable-next-line
            throw BreakException;
          }
        });
      } catch (e) {
        return false;
      }
      return true;
    },

    setCurrentPayment(payment){
      // 透過vuex回傳資料讓修改刪除modal讀取
      this.$store.dispatch('consultant/setCurrentPaymentDatas', payment);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./payment";
</style>
