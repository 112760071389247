const editCertificatesLang = {
  messages: {
    tw: {
      'add_certificates': '新增證書',
      'edit_certificates': '編輯證書',
      'please_input': '請輸入 ',
      'type': '類型',
      'name': '名稱',
      'image': '圖片',
      'ok': '確定',
      'cancel': '取消',
      'degree': '學位',
      'english_proficiency': '英文水平證照',
      'teaching_cert': '教學證照',
      'teaching_diploma': '教學畢業證書',
      'set_up_success': '證照設定成功',
      'set_up_fail': '證照設定失敗',
    },
    en: {
      'add_certificates': 'Add Certificates',
      'edit_certificates': 'Edit Certificates',
      'please_input': 'Please Input ',
      'type': 'Type',
      'name': 'Name',
      'image': 'Image',
      'ok': 'OK',
      'cancel': 'Cancel',
      'degree': 'Degree',
      'english_proficiency': 'English proficiency certificate',
      'teaching_cert': 'Teaching certificate',
      'teaching_diploma': 'Teaching diploma',
      'set_up_success': 'Your certificates set up successful!',
      'set_up_fail': 'Your certificates set up fail!',
    },
  },
};

export {
  editCertificatesLang
};
