var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "remove_certificates_modal",
      "cancel-title": 'Cancle',
      "ok-title": 'OK',
      "title": _vm.$t('remove_certificates'),
      "effect": "fade",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        return _vm.submit();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('remove_confirm_mes')) + "  \"" + _vm._s(_vm.certificates.name) + "\" ?")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }