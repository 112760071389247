<template lang="pug">
  b-form-group(horizontal='' :label-cols='6' breakpoint='sm' :label="$t('paypal_account')" label-for='txtPaypalEmail')
    b-form-input#txtPaypalEmail(v-model="data.paypalEmail" name='paypalEmail' :placeholder="$t('please_input') + $t('paypal_account')")
</template>
<script>
import { mapState } from 'vuex';
import { editPaymentLang } from './../lang';
export default {
  name: 'AddNewPayment',

  props: {
    // eslint-disable-next-line
    isAddMode: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },

  i18n: editPaymentLang,

  data() {
    return {
      data: {
        paypalEmail: '',
      },
    };
  },

  computed: {
    ...mapState({
      payment: state => state.consultant.currentPaymentDatas,
    }),
  },

  mounted() {
    if (!this.isAddMode){
      this.setEditData();
    }
  },

  methods: {
    setEditData(){
      this.data.paypalEmail = this.payment.paypal_email;
    },
  },
};
</script>

