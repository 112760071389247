const internetSpeedLang = {
  messages: {
    tw: {
      'my_internet_speeds': '網路速度',
      'start_test': '開始測試',
      'type': '類型',
      'download': '下載',
      'upload': '上傳',
      'time': '更新時間',
      'work': '辦公室',
      'home': '家裡',
      'other': '其他',
    },
    en: {
      'my_internet_speeds': 'My Internet Speed',
      'start_test': 'Start Test',
      'type': 'Type',
      'download': 'Download',
      'upload': 'Upload',
      'time': 'Time',
      'work': 'Work',
      'home': 'Home',
      'other': 'Other',
    },
  },
};

export {
  internetSpeedLang
};
