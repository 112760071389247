<template lang="pug">
  b-modal#edit_certificates_modal(
    :cancel-title="$t('cancel')"
    :ok-title="$t('ok')"
    :title="isAddMode ? $t('add_certificates') : $t('edit_certificates')"
    effect="fade"
    no-close-on-backdrop
    @ok="submit"
    )
    b-form-group(:label-cols='3' breakpoint='sm' :label="$t('type')" label-for='ddlType')
      b-form-select#ddlType(v-model="certificatesData.type" name='type' :options='types')
    b-form-group(:label-cols='3' breakpoint='sm' :label="$t('name')" label-for='txtName')
      b-form-input#txtName(v-model="certificatesData.name" name='name' :placeholder="$t('please_input') + $t('name')")
    b-form-group(:label-cols='3' breakpoint='sm' :label="$t('image')" label-for='my_image')
      b-form-file#certificatesImage(v-model="certificatesData.image" ref='certificatesImage' name='image' accept='image/jpeg, image/jpg, image/png,' @change="checkImageFileType($event, 'certificatesImage')")

</template>
<script>
import lioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import { editCertificatesLang } from './lang';
export default {
  name: 'EditCertificates',

  props: {
    // eslint-disable-next-line
    certificates: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  i18n: editCertificatesLang,

  data() {
    return {
      isAddMode: !Object.prototype.hasOwnProperty.call(this.certificates, 'qualification_id'),

      certificatesData: {
        type: '',
        name: '',
        image: null,
      },

      types: [
        { text: this.$t('degree'), value: 'degree' },
        { text: this.$t('teaching_cert'), value: 'teaching_cert' },
        { text: this.$t('teaching_diploma'), value: 'teaching_diploma' },
        { text: this.$t('english_proficiency'), value: 'english_proficiency' },
      ],
    };
  },

  mounted() {
    this.setDefaultCertificatesData();
  },

  methods: {
    submit(){
      if (this.isAddMode) {
        this.addCertificates();
      } else {
        this.editCertificates();
      }
    },

    async addCertificates(){
      try {
        const addResponse = await lioshutanApi.consultant.addConsultantCertificates(this.certificatesData);
        if (addResponse.data.message === 'OK'){
          this.editEventSuccess();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('set_up_fail'));
      }
    },

    async editCertificates(){
      try {
        const editResponse = await lioshutanApi.consultant.editConsultantCertificates(this.certificatesData);
        if (editResponse.data.data.status === true){
          this.editEventSuccess();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('set_up_fail'));
      }
    },

    editEventSuccess(){
      alertMessage(this.$t('success'), 'success', this.$t('set_up_success'));
      this.getQualificationsData();
    },

    setDefaultCertificatesData(){
      if (this.isAddMode) {
        this.certificatesData = {
          type: '',
          name: '',
          image: null,
        };
      } else {
        this.certificatesData = this.certificates;
      }
    },

    checkImageFileType(e, ref){
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        alertMessage(this.$t('warning'), 'info', 'File Format: jpeg jpg png');
        this.$refs[ref].reset();
        return false;
      }
    },

    async getQualificationsData(){
      // 取得資料並用vuex管理同page資料 (1.方便子層直接取得資料 2.編輯及新增後資料刷新)
      const consultantQualificationsRes = await lioshutanApi.consultant.getConsultantQualifications();
      this.$store.dispatch('consultant/setQualificationsDatas', consultantQualificationsRes.data.data);
    },
  },
};

</script>

<style></style>
