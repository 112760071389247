const certificatesLang = {
  messages: {
    tw: {
      'my_certificates': '我的證書',
      'operations': '操作',
      'type': '類型',
      'name': '名稱',
      'image': '圖片',
      'add_qualification': '新增證書',
      'english_proficiency': '英文水平證照',
      'teaching_cert': '教學證照',
      'teaching_diploma': '教學畢業證書',
    },
    en: {
      'my_certificates': 'My Certificates',
      'operations': 'Operations',
      'type': 'Type',
      'name': 'Name',
      'image': 'Image',
      'add_qualification': 'Add Qualification',
      'english_proficiency': 'English Proficiency Certificate',
      'teaching_cert': 'Teaching Certificate',
      'teaching_diploma': 'Teaching Diploma',
    },
  },
};

export {
  certificatesLang
};
