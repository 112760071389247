<template lang="pug">
table.table(style='width:100%')
  tbody
    tr.tr-title.text-center
      th(colspan='2' style='')
        h4
          input.default-payment-radio(v-model="defaultPayment" :value="payment.id" type='radio' name='defaultPayment' v-on:change="selectDefaultPayment(payment.id)")
          | {{ $t("paypal") }}
          b-badge.ml-2(variant='success' v-if="payment.default_payment") {{ $t('default') }}
    tr
      td
        strong {{ $t("paypal_account") }}
      td(v-if='_isMobile()') {{ payment.paypal_email }}
      td(v-else='')
        strong {{ $t("operations") }}
    tr
      td(v-if='_isMobile()')
        strong {{ $t("operations") }}
      td(v-else='') {{ payment.paypal_email }}
      td
        a.btn-box-tool.mr-2(href='#' v-b-modal.edit_payment_modal @click="setCurrentPayment(payment)")
          font-awesome-icon(icon="pen")
        a.btn-box-tool(href='#' v-b-modal.remove_payment_modal @click="setCurrentPayment(payment)")
          font-awesome-icon(icon="times")
</template>
<script>
import PaymentMixin from './../mixin/paymentMixin.vue';
export default {
  name: 'PaypalTable',

  mixins: [PaymentMixin],

  props: {
    // eslint-disable-next-line
    payment: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },

  mounted(){
    this.getDefaultPatment();
  },

  methods: {
    getDefaultPatment(){
      if (this.payment.default_payment === 1){
        this.defaultPayment = this.payment.id;
      }
    },
  },
};
</script>
