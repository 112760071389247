var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.$t('my_payment_method')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('div', {
    staticClass: "col-xs-12 table-responsive vuetable-overscroll"
  }, [_c('h3', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.paymentAlertMes()))])], 1), _vm._l(_vm.consultantPayment, function (payment, index) {
    return _c('div', {
      key: 'payment-' + index
    }, [payment.payment === 'taiwan_bank' ? _c('taiwan-bank-table', {
      attrs: {
        "payment": payment
      }
    }) : _vm._e(), payment.payment === 'paypal' ? _c('paypal-table', {
      attrs: {
        "payment": payment
      }
    }) : _vm._e()], 1);
  }), _c('br'), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.edit_payment_modal",
      modifiers: {
        "edit_payment_modal": true
      }
    }],
    staticClass: "btn btn-outline-primary btn-block",
    on: {
      "click": function ($event) {
        return _vm.setCurrentPayment({});
      }
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.$t("create_payment")))])]), _c('edit-payment'), _c('remove-payment')], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }