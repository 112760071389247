<template lang="pug">
  .row
    .col-md-6
      profile-data
    .col-md-6
      //- 1 comment start role 7 14
      .col-md-12
        vip-preference
      .col-md-12
        payment
      //- 1 comment end
      .col-md-12
        certificates
      //- 2 comment start role 7 14
      .col-md-12
        device
      //- Todo網速有時間處理 已開卡片-Bc15vCBc(老師網速測試) by Asa
      //- .col-md-12
      //-   internet-speed
      //- 2 comment end
</template>

<script>
import profileData from '@/components/consultant/profile/profileData';
import vipPreference from '@/components/consultant/profile/vipPreference';
import payment from '@/components/consultant/profile/payment';
import certificates from '@/components/consultant/profile/certificates';
import device from '@/components/consultant/profile/device';
import internetSpeed from '@/components/consultant/profile/internetSpeed';
import lioshutanApi from '@/api';
import wuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

export default {
  name: 'ProfilePage',

  components: {
    profileData,
    vipPreference,
    payment,
    certificates,
    device,
    internetSpeed,
  },

  mixins: [wuwowLoadingMixin],

  data() {
    return {
    };
  },

  created(){
    this.getConsultantBaseData();
  },

  methods: {
    async getConsultantBaseData(){
      const tag = this.startLoading();
      // 取得資料並用vuex管理同page資料 (1.方便子層直接取得資料 2.編輯及新增後資料刷新)
      const consultantProfileRes = await lioshutanApi.consultant.getConsultantProfile();
      const consultantEvaluationScoreRes = await lioshutanApi.consultant.getConsultantEvaluationScore({ st: 'personal_score' });
      const consultantPaymentRes = await lioshutanApi.consultant.getConsultantPayment();
      const consultantQualificationsRes = await lioshutanApi.consultant.getConsultantQualifications();
      const consultantDevicesRes = await lioshutanApi.consultant.getConsultantDevices();
      const consultantLocationsSpeedRes = await lioshutanApi.consultant.getConsultantLocationsSpeed();
      this.$store.dispatch('consultant/setBaseProfileDatas', consultantProfileRes.data.data.user);
      this.$store.dispatch('consultant/setEvaluationScoreDatas', consultantEvaluationScoreRes.data.data);
      this.$store.dispatch('consultant/setPaymentDatas', consultantPaymentRes.data.data);
      this.$store.dispatch('consultant/setQualificationsDatas', consultantQualificationsRes.data.data);
      this.$store.dispatch('consultant/setDevicesDatas', consultantDevicesRes.data.data);
      this.$store.dispatch('consultant/setLocationsSpeedDatas', consultantLocationsSpeedRes.data.data);
      this.endLoading(tag);
    },
  },
};
</script>

<style>

</style>
