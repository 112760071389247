<template lang="pug">
div
  .row
    .col-sm-11
      b-form-group(horizontal='' :label-cols='4' :label="'*'+$t('bank_code')" label-for='bank')
        vue-multiselect#bank(v-model="bank" name='bank' :options="bankOptions" :custom-label="bankDataName" :placeholder="$t('type_to_select')" :allow-empty="false" label="code" track-by="code")
  .row(v-if="branchBankOptions.length > 0")
    .col-sm-11
      b-form-group(horizontal='' :label-cols='4' :label="'*'+$t('bank_branch')" label-for='bank_branch')
        vue-multiselect#bank_branch(v-model="branchBank" name='bank_branch' :options="branchBankOptions" :custom-label="branchBankDataName" :placeholder="$t('type_to_select')" label="code" track-by="code")
  .row
    .col-sm-11
      b-form-group(horizontal='' :label-cols='4' breakpoint='sm' :label="'*'+$t('bank_account')" label-for='txtBankAccount')
        b-form-input#txtBankAccount(v-model="data.bankAccount" type='number' name='bankAccount' :placeholder="$t('please_input') + $t('bank_account')")
  .row
    .col-sm-11
      b-form-group(horizontal='' :label-cols='4' breakpoint='sm' :label="'*'+$t('bank')+' '+$t('image_path')" label-for='image_path' )
        b-form-file(v-model="data.image" ref='bankImage' name='bankImage' accept='image/jpeg, image/jpg, image/png,' @change="checkImageFileType($event, 'bankImage')")
        | {{ " Max Size:2MB" }}
</template>
<script>
import { mapState } from 'vuex';
import VueMultiselect from 'vue-multiselect';
import { alertMessage } from '@/utils/sweetAlert.js';
import { editPaymentLang } from './../lang';
export default {
  name: 'AddNewPayment',

  components: {
    VueMultiselect,
  },

  props: {
    // eslint-disable-next-line
    isAddMode: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },

  i18n: editPaymentLang,

  data() {
    return {
      data: {
        bankCode: '',
        bankBranchCode: '',
        bankAccount: '',
        image: null,
      },

      bank: '',

      branchBank: '',

      branchBankOptions: [],
    };
  },

  computed: {
    ...mapState({
      payment: state => state.consultant.currentPaymentDatas,
      bankOptions: state => state.common.bankOptions,
      allBranchBankOptions: state => state.common.allBranchBankOptions,
    }),
  },

  watch: {
    bank(val, old) {
      this.branchBankOptions = this.allBranchBankOptions[val.code];
      this.data.bankCode = val.code;
      this.setMultiselectsHeight();
      if (this.isAddMode){
        this.branchBank = '';
      }
    },

    branchBank(val, old) {
      this.data.bankBranchCode = val.code;
    },
  },

  mounted() {
    if (!this.isAddMode){
      this.setEditData();
    }
  },

  methods: {
    branchBankDataName({ code, name }){
      return `${code} — ${name}`;
    },

    bankDataName({ code, chinese, english }){
      return `${code} — ${chinese} ${english}`;
    },

    checkImageFileType(e, ref){
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        alertMessage(this.$t('warning'), 'info', 'File Format: jpeg jpg png');
        this.$refs[ref].reset();
        return false;
      }
    },

    setMultiselectsHeight(){
      const multiselects = document.getElementsByClassName('multiselect__tags');
      for (let i = 0; i < multiselects.length; i++) {
        multiselects[i].style.height = 'auto';
      }
    },

    setEditData(){
      this.bank = this.payment.bank;
      this.branchBankOptions = this.allBranchBankOptions[this.payment.bank.code];
      this.branchBank = this.payment.bank_branch;
      this.data.bankCode = this.payment.bank.code;
      this.data.bankBranchCode = this.payment.bank_branch.code;
      this.data.bankAccount = this.payment.bank_number;
      if (this.payment.image_path !== '') {
        this.data.image = this.payment.image_path;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
