const paymentLang = {
  messages: {
    tw: {
      'my_payment_method': '我的付款方式',
      'select_your_payment_method': '選擇你的付款方式',
      'create_your_payment_method': '建立你的付款方式',
      'taiwan_bank': '台灣的銀行',
      'default': '預設',
      'bank_code': '銀行代碼',
      'bank_branch': '分行名稱',
      'bank_account': '銀行帳號',
      'operations': '操作',
      'paypal': 'Paypal',
      'paypal_account': 'Paypal 帳號',
      'create_payment': '建立付款',
      'create_payment_mes': '建立你的收款方式',
      'select_payment_mes': '選擇你的收款方式',
      'set_up_success': '設定成功',
      'set_up_fail': '設定失敗',
    },
    en: {
      'my_payment_method': 'My Payment Method',
      'select_your_payment_method': 'Select your payment method',
      'create_your_payment_method': 'Create your payment method',
      'taiwan_bank': 'Taiwan Bank',
      'default': 'default',
      'bank_code': 'Bank Code',
      'bank_branch': 'Bank Branch',
      'bank_account': 'Bank Account',
      'operations': 'Operations',
      'paypal': 'Paypal',
      'paypal_account': 'Paypal Account',
      'create_payment': 'Create Payment',
      'create_payment_mes': 'Create your payment method',
      'select_payment_mes': 'Select your payment method',
      'set_up_success': 'Your set up is successful!',
      'set_up_fail': 'Your set up is fail!',
    },
  },
};

export {
  paymentLang
};
