const editPaymentLang = {
  messages: {
    tw: {
      'add_payment': '新增付款',
      'edit_payment': '編輯付款',
      'payment_method': '付款方式',
      'taiwan_bank': '台灣的銀行',
      'bank_code': '銀行代碼',
      'bank_branch': '分行名稱',
      'bank_account': '銀行帳號',
      'bank': '銀行',
      'image_path': '圖片位址',
      'please_input': '請輸入',
      'paypal': 'Paypal',
      'paypal_account': 'Paypal 帳號',
      'cancel': '取消',
      'ok': '確認',
      'type_to_select': '輸入相關字 ...',
      'set_up_success': '設定成功',
      'set_up_fail': '設定失敗',
    },
    en: {
      'add_payment': 'Add New Payment',
      'edit_payment': 'Edit Payment',
      'payment_method': 'Payment Method',
      'taiwan_bank': 'Taiwan Bank',
      'bank_code': 'Bank Code',
      'bank_branch': 'Bank Branch',
      'bank_account': 'Bank Acount',
      'bank': 'Bank',
      'image_path': 'Image Path',
      'please_input': 'Please Input ',
      'paypal': 'Paypal',
      'paypal_account': 'Paypal Account',
      'cancel': 'Cancel',
      'ok': 'OK',
      'type_to_select': 'Type to select ...',
      'set_up_success': 'Your set up is successful!',
      'set_up_fail': 'Your set up is fail!',
    },
  },
};

export {
  editPaymentLang
};
