var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.$t('my_internet_speeds')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("type")))]), _c('th', [_vm._v(_vm._s(_vm.$t("download")))]), _c('th', [_vm._v(_vm._s(_vm.$t("upload")))]), _c('th', [_vm._v(_vm._s(_vm.$t("time")))])]), _c('tr'), _vm._l(_vm.consultantLocationsSpeed.slice(0, 5), function (locationsSpeed, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [[_c('font-awesome-icon', {
      attrs: {
        "icon": _vm.getLocationIcon(locationsSpeed.description)
      }
    }), _vm._v(" " + _vm._s(_vm.$t(locationsSpeed.description)))]], 2), _c('td', {
      class: _vm.isTooSlow(locationsSpeed.trans_upload, 6)
    }, [_vm._v(_vm._s(locationsSpeed.trans_download) + " Mbps")]), _c('td', {
      class: _vm.isTooSlow(locationsSpeed.trans_upload, 2)
    }, [_vm._v(_vm._s(locationsSpeed.trans_upload) + " Mbps")]), _c('td', [_vm._v(_vm._s(_vm.calCreatedTime(locationsSpeed.created_at)))])]);
  })], 2)]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.test_net_speed_modal",
      modifiers: {
        "test_net_speed_modal": true
      }
    }],
    staticClass: "btn btn-outline-primary btn-block"
  }, [_c('b', [_vm._v(_vm._s(_vm.$t("start_test")))])]), _c('test-net-speed-modal')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }