var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "edit_device_modal",
      "cancel-title": _vm.$t('cancel'),
      "ok-title": _vm.$t('ok'),
      "title": _vm.isAddMode ? _vm.$t('add_device') : _vm.$t('edit_device'),
      "effect": "fade",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        return _vm.submit();
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 4,
      "breakpoint": "sm",
      "label": _vm.$t('type'),
      "label-for": "ddlType"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlType",
      "options": _vm.types
    },
    model: {
      value: _vm.deviceData.type,
      callback: function ($$v) {
        _vm.$set(_vm.deviceData, "type", $$v);
      },
      expression: "deviceData.type"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 4,
      "breakpoint": "sm",
      "label": _vm.$t('brand'),
      "label-for": "ddlBrand"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlBrand",
      "options": _vm.brands
    },
    model: {
      value: _vm.deviceData.brand,
      callback: function ($$v) {
        _vm.$set(_vm.deviceData, "brand", $$v);
      },
      expression: "deviceData.brand"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 4,
      "breakpoint": "sm",
      "label": _vm.$t('model'),
      "label-for": "txtModel"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "txtModel",
      "name": "model",
      "placeholder": _vm.$t('please_input') + ' ' + _vm.$t('model')
    },
    model: {
      value: _vm.deviceData.model,
      callback: function ($$v) {
        _vm.$set(_vm.deviceData, "model", $$v);
      },
      expression: "deviceData.model"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 4,
      "breakpoint": "sm",
      "label": _vm.$t('os'),
      "label-for": "ddlOsFamily"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlOsFamily",
      "options": _vm.systems
    },
    model: {
      value: _vm.deviceData.os_family,
      callback: function ($$v) {
        _vm.$set(_vm.deviceData, "os_family", $$v);
      },
      expression: "deviceData.os_family"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 4,
      "breakpoint": "sm",
      "label": _vm.$t('os_version'),
      "label-for": "txtOsVersion"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "txtOsVersion",
      "name": "osVersion",
      "placeholder": _vm.$t('please_input') + ' ' + _vm.$t('os_version')
    },
    model: {
      value: _vm.deviceData.os_version,
      callback: function ($$v) {
        _vm.$set(_vm.deviceData, "os_version", $$v);
      },
      expression: "deviceData.os_version"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 4,
      "breakpoint": "sm",
      "label": _vm.$t('browser'),
      "label-for": "ddlBrowser"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlBrowser",
      "options": _vm.browsers
    },
    model: {
      value: _vm.deviceData.browser,
      callback: function ($$v) {
        _vm.$set(_vm.deviceData, "browser", $$v);
      },
      expression: "deviceData.browser"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 4,
      "breakpoint": "sm",
      "label": _vm.$t('browser_version'),
      "label-for": "txtBrowserVersion"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "txtBrowserVersion",
      "name": "browserVersion"
    },
    model: {
      value: _vm.deviceData.browser_version,
      callback: function ($$v) {
        _vm.$set(_vm.deviceData, "browser_version", $$v);
      },
      expression: "deviceData.browser_version"
    }
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }