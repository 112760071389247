<template lang="pug">
  b-modal#remove_certificates_modal(
    :cancel-title="'Cancle'"
    :ok-title="'OK'"
    :title="$t('remove_certificates')"
    effect="fade"
    no-close-on-backdrop
    @ok="submit()"
    )
    span {{ $t('remove_confirm_mes') }} &emsp;"{{ certificates.name }}" ?
</template>
<script>
import LioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from './lang';

export default {
  name: 'RemoveCertificates',

  i18n,

  props: {
    // eslint-disable-next-line
    certificates: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    async submit(){
      try {
        const removeResponse = await LioshutanApi.consultant.removeConsultantCertificates({ qualificationId: this.certificates.qualification_id });
        if (removeResponse.data.data.status === true){
          alertMessage(this.$t('success'), 'success', this.$t('remove_up_success'));
          this.getQualificationsData();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('remove_up_fail'));
      }
    },

    async getQualificationsData(){
      // 取得資料並用vuex管理同page資料 (1.方便子層直接取得資料 2.編輯及新增後資料刷新)
      const consultantQualificationsRes = await LioshutanApi.consultant.getConsultantQualifications();
      this.$store.dispatch('consultant/setQualificationsDatas', consultantQualificationsRes.data.data);
    },
  },
};

</script>
