const addDeviceLang = {
  messages: {
    tw: {
      'add_device': '新增設備',
      'edit_device': '新增設備',
      'type': '類型',
      'device': '設備',
      'model': '機型',
      'os': '作業系統',
      'os_version': '作業系統版本',
      'browser': '瀏覽器',
      'browser_version': '瀏覽器版本',
      'cancel': '取消',
      'ok': '確定',
      'please_input': '請輸入',
      'set_up_success': '設備設定成功',
      'set_up_fail': '設備設定失敗',
    },
    en: {
      'add_device': 'Add Device',
      'edit_device': 'Edit Device',
      'type': 'Type',
      'device': 'Device',
      'model': 'Model',
      'os': 'OS',
      'os_version': 'OS Version',
      'browser': 'Browser',
      'browser_version': 'Browser Version',
      'cancel': 'Cancel',
      'ok': 'OK',
      'please_input': 'Please Input',
      'set_up_success': 'Your device set up successful!',
      'set_up_fail': 'Your device set up fail!',
    },
  },
};

export {
  addDeviceLang
};
