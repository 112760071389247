const queryMonthLang = {
  messages: {
    tw: {
      'check_other_month': '查看其他月份',
      'query': '查詢',
      'close': '關閉',
      'no_rating': '沒有評級',
    },
    en: {
      'check_other_month': 'Check other months',
      'query': 'Query',
      'close': 'Close',
      'no_rating': 'No Rating',
    },
  },
};

export {
  queryMonthLang
};
