<template lang="pug">
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title {{ $t('student_preference') }}
    .wuwow-card-body
      .preference.d-flex.justify-content-around.mt-1
        div
          font-awesome-icon(icon="thumbs-up")
          |  {{consultantEvaluationScore.like_amount}}
        div
          font-awesome-icon(icon="thumbs-down")
          |  {{consultantEvaluationScore.dislike_amount}}
</template>

<script>
import { mapState } from 'vuex';
import { vipPreferenceLang } from './lang';
export default {
  name: 'VipPreference',

  i18n: vipPreferenceLang,

  computed: {
    ...mapState({
      consultantEvaluationScore: state => state.consultant.evaluationScoreDatas,
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "./vipPreference"
</style>
