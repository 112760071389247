<template lang="pug">
  div
    h3.text-center
      span(v-if="queried") {{ queryMonth + ' Average Rating: ' + score }}
      span(v-else) {{ 'This Month\'s Average Rating:'}}{{getScoreAvg(this.consultantEvaluationScore.score.avg)}}
    div(align="center")
      b-button(type="button" variant="primary" @click="otherMonth()") {{ $t('check_other_month') }}
    div(v-if="show_query_month" align="center" class="query-box")
      datepicker(v-model="queryOtherMonth" :minimumView="'month'" :maximumView="'month'" :bootstrap-styling="true" format="yyyy-MM")
      div
        b-button(type="button" class="" variant="primary" @click="queryOtherMonthScore(queryOtherMonth)") {{ $t('query') }}
        b-button(type="button" class="" variant="primary" @click="closeOtherMonth()") {{ $t('close') }}
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { queryMonthLang } from './lang.js';
import LioshutanApi from '@/api';

export default {
  name: 'QueryMonthRate',

  components: {
    Datepicker,
  },

  i18n: queryMonthLang,

  props: {
    consultantEvaluationScore: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      queryOtherMonth: '',
      queried: false,
      show_query_month: false,
      score: 0,
      queryMonth: '',
    };
  },

  mounted() {},

  methods: {
    async queryOtherMonthScore(date) {
      this.queried = true;
      this.queryMonth = moment(date).format('YYYY-MM');
      const params = {
        st: 'personal_score',
        ds: this.queryMonth,
        de: this.queryMonth,
      };
      const response = await LioshutanApi.consultant.getConsultantEvaluationScore(params);
      this.score = this.getScoreAvg(response.data.data.score.avg);
    },

    otherMonth() {
      this.show_query_month = !this.show_query_month;
    },

    closeOtherMonth() {
      this.queryOtherMonth = '';
      this.show_query_month = false;
    },

    getScoreAvg(score){
      const result = Math.floor(score * 100) / 100;
      return (result > 0) ? result : this.$t('no_rating');
    },
  },
};
</script>

<style lang="scss" scope>
@import './queryMonthRate';

</style>
