var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "list-group-item"
  }, [_c('b', [_vm._v(_vm._s(_vm.$t("identification_image_path")))]), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('p', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v("Front")]), _c('a', {
    attrs: {
      "target": "_blank"
    }
  }, [_vm.consultantBaseProfile.identification_image_path ? _c('b-img', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "src": _vm.consultantBaseProfile.identification_image_path,
      "center": "",
      "alt": "Your Identification Image"
    }
  }) : _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.$t("please") + ' ' + _vm.$t("upload") + ' ' + _vm.$t("your_identification_image")))])], 1)]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('p', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v("Back")]), _c('a', {
    attrs: {
      "target": "_blank"
    }
  }, [_vm.consultantBaseProfile.identification_back_image_path ? _c('b-img', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "src": _vm.consultantBaseProfile.identification_back_image_path,
      "center": "",
      "alt": "Your Identification Back Image"
    }
  }) : _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.$t("please") + ' ' + _vm.$t("upload") + ' ' + _vm.$t("your_identification_back_image")))])], 1)])]), _c('br'), _c('h3', {
    staticClass: "profile-username text-center"
  }, [_vm._v(_vm._s(_vm.$t("your_identification_image")))]), _c('b', [_vm._v(_vm._s(_vm.$t("identification")))]), _c('span', {
    staticClass: "float-right"
  }, [_vm._v(_vm._s(_vm.consultantBaseProfile.identification))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }