<template lang="pug">
table.table.w-100
  tbody
    tr.tr-title.text-center
      th(colspan='4')
        h4
          input.default-payment-radio(v-model="defaultPayment" :value="payment.id" type='radio' name='defaultPayment' v-on:change="selectDefaultPayment(payment.id)")
          | {{ $t("taiwan_bank") }}
          b-badge.ml-2(variant='success' v-if="payment.default_payment") {{ $t('default') }}
        div
          a(v-if="payment.image_path" :href="payment.image_path" target='_blank')
            img.profile-user-img.img-responsive.img-rounded.w-100(:src="payment.image_path" alt='Your Passbook Picture')
          br
          h5
            | {{ payment.bank.chinese }}
            br
            | {{ payment.bank.english }}
    tr
      td
        strong {{ $t("bank_code") }}
      td(v-if='_isMobile()') {{ payment.bank.code }}
      td(v-else)
        strong {{ $t("bank_branch") }}
    tr
      td(v-if='_isMobile()')
        strong {{ $t("bank_branch") }}
      td(v-else) {{ payment.bank.code }}
      td {{ payment.bank_branch.name }}
    tr
      td
        strong {{ $t("bank_account") }}
      td(v-if='_isMobile()') {{ payment.bank_number }}
      td(v-else)
        strong {{ $t("operations") }}
    tr
      td(v-if='_isMobile()')
        strong {{ $t("operations") }}
      td(v-else) {{ payment.bank_number }}
      td
        a.btn-box-tool.mr-2(href='#' v-b-modal.edit_payment_modal @click="setCurrentPayment(payment)")
          font-awesome-icon(icon="pen")
        a.btn-box-tool(href='#' v-b-modal.remove_payment_modal @click="setCurrentPayment(payment)")
          font-awesome-icon(icon="times")
</template>
<script>
import PaymentMixin from './../mixin/paymentMixin.vue';
export default {
  name: 'TaiwanBankTable',

  mixins: [PaymentMixin],

  props: {
    // eslint-disable-next-line
    payment: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },

  mounted(){
    this.getDefaultPatment();
  },

  methods: {
    getDefaultPatment(){
      if (this.payment.default_payment === 1){
        this.defaultPayment = this.payment.id;
      }
    },

    setCurrentPayment(payment){
      // 透過vuex回傳資料讓修改刪除modal讀取
      this.$store.dispatch('consultant/setCurrentPaymentDatas', payment);
    },
  },
};
</script>
