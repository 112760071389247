<template lang="pug">
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title {{ $t('my_profiles') }}
    .wuwow-card-body
      query-month-rate(v-if="!isRecruitment" :consultantEvaluationScore="consultantEvaluationScore")
      div.row.consultant__item__wrapper
        .row.col-md-12.consultant__item
          span.col-md-12.consultant__item__photo
            a(target="_blank" :href="consultantBaseProfile.picture")
              div.item__photo__wrapper( :style="{ backgroundImage: 'url(' + consultantBaseProfile.picture + ')'}" )
      h3.profile-username.text-center {{ consultantBaseProfile.username }}
      p.text-muted.text-center(v-if="consultantBaseProfile.chinese_name !== 'unfilled'") {{ consultantBaseProfile.chinese_name }}
      ul.list-group.list-group-unbordered
        identification-info(:consultantBaseProfile="consultantBaseProfile")
        info-span(v-for="(list, index) in infoTextList" :key="'lisk'+index" :title="list" :value="valueHandle(list, consultantBaseProfile[list])" )
        info-link(v-for="(link, index) in infoLinkList" :key="'link'+index" :title="link" :value="consultantBaseProfile[link]" )
      button.btn.btn-outline-primary.btn-block.mt-3(v-b-modal.edit-porfile-modal)
        b {{ $t("edit_profiles") }}
      edit-porfile-modal(:consultantBaseProfile="consultantBaseProfile")
</template>

<script>
import { mapState } from 'vuex';
import queryMonthRate from '@/components/consultant/profile/profileData/queryMonthRate';
import identificationInfo from '@/components/consultant/profile/profileData/identificationInfo';
import infoSpan from '@/components/consultant/profile/profileData/infoSpan';
import infoLink from '@/components/consultant/profile/profileData/infoLink';
import editPorfileModal from './editPorfile';
import { ProfileDataLang } from './lang.js';

export default {
  name: 'Profile',

  components: {
    queryMonthRate,
    identificationInfo,
    infoSpan,
    infoLink,
    editPorfileModal,
  },

  i18n: ProfileDataLang,

  data() {
    return {
      infoTextList: [
        'username', 'gender', 'email', 'work_phone', 'birthday', 'address', 'second_address',
        'health_card', 'education', 'school_name', 'major', 'nationality', 'occupation',
        'self_introduction', 'suggestion',
      ],
      infoLinkList: [
        'introduction_video', 'resume',
      ],
    };
  },

  computed: {
    ...mapState({
      consultantBaseProfile: state => state.consultant.baseProfileDatas,
      consultantEvaluationScore: state => state.consultant.evaluationScoreDatas,
    }),

    isRecruitment(){
      return (this.consultantBaseProfile.hb_recruitment_status !== null && this.consultantBaseProfile.hb_recruitment_status !== 4);
    },
  },

  methods: {
    valueHandle(title, value){
      if (title === 'gender'){
        return this.getGender(value);
      } else if (title === 'health_card'){
        return this.getYesOrNo(value);
      } else if (title === 'education'){
        return this.$t(value);
      } else if (title === 'preferred_topic'){
        return this.getPreferredTopic(value);
      } else {
        return value;
      }
    },

    getGender(gender){
      if (gender === 'M'){
        return this.$t('male');
      } else if (gender === 'F'){
        return this.$t('female');
      } else {
        return null;
      }
    },

    getYesOrNo(value){
      (value === 1) ? this.$t('yes') : this.$t('no');
    },

    getPreferredTopic(value){
      let preferredTopics = '';
      if (value){
        value.forEach((element) => {
          preferredTopics += element.relation_interested_topic.title + '<br>';
        });
        return preferredTopics;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./profileData";
.list-group-item{
  margin-bottom: 0;
}
</style>
