var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.$t('my_devices')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("operations")))]), _c('th', [_vm._v(_vm._s(_vm.$t("type")))]), _c('th', [_vm._v(_vm._s(_vm.$t("device")))]), _c('th', [_vm._v(_vm._s(_vm.$t("os")))]), _c('th', [_vm._v(_vm._s(_vm.$t("browser")))])]), _vm._l(_vm.consultantDevices.slice(0, 20), function (device, index) {
    return _c('tr', {
      key: 'device-' + index
    }, [_c('td', [_c('a', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.remove_device_modal",
        modifiers: {
          "remove_device_modal": true
        }
      }],
      staticClass: "btn-box-tool mr-2",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.setCurrentEditDevice(device);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "times"
      }
    })], 1), _c('a', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.edit_device_modal",
        modifiers: {
          "edit_device_modal": true
        }
      }],
      staticClass: "btn-box-tool",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.setCurrentEditDevice(device);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "pen"
      }
    })], 1)]), _c('td', [_c('font-awesome-icon', {
      attrs: {
        "icon": device.type
      }
    })], 1), _c('td', [_vm._v(_vm._s(device.brand) + " " + _vm._s(device.model))]), _c('td', [_vm._v(_vm._s(device.os_family) + " " + _vm._s(device.os_version))]), _c('td', [_vm._v(_vm._s(device.browser) + " " + _vm._s(device.browser_version))])]);
  })], 2)]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.edit_device_modal",
      modifiers: {
        "edit_device_modal": true
      }
    }],
    staticClass: "btn btn-outline-primary btn-block",
    on: {
      "click": function ($event) {
        return _vm.setCurrentEditDevice({});
      }
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.$t("add_device")))])]), _c('edit-device-modal', {
    key: 'Edit-' + _vm.currentEditDevice.device_id,
    attrs: {
      "device": _vm.currentEditDevice
    }
  }), _c('remove-device-modal', {
    key: 'Remove-' + _vm.currentEditDevice.device_id,
    attrs: {
      "device": _vm.currentEditDevice
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }