<script>
import LioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import { paymentLang } from './../lang';
export default {
  name: 'PaymentMixin',

  i18n: paymentLang,

  data() {
    return {
      defaultPayment: '',
    };
  },

  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    async selectDefaultPayment(paymentId){
      try {
        const DefaultPaymentDatas = {
          paymentId: paymentId,
          dp: 1,
        };
        const setResponse = await LioshutanApi.consultant.setConsultantDefaultPayment(DefaultPaymentDatas);
        if (setResponse.data.message === 'OK'){
          alertMessage(this.$t('success'), 'success', this.$t('set_up_success'));
          this.getconsultantPaymentDatas();
        }
      } catch (err){
        alertMessage(this.$t('failed'), 'error', this.$t('set_up_fail'));
      }
    },

    setCurrentPayment(payment){
      // 透過vuex回傳資料讓修改刪除modal讀取
      this.$store.dispatch('consultant/setCurrentPaymentDatas', payment);
    },

    async getconsultantPaymentDatas(){
      const consultantPaymentRes = await LioshutanApi.consultant.getConsultantPayment();
      this.$store.dispatch('consultant/setPaymentDatas', consultantPaymentRes.data.data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../payment";
</style>
