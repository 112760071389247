var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.$t('my_certificates')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('operations')))]), _c('th', [_vm._v(_vm._s(_vm.$t('type')))]), _c('th', [_vm._v(_vm._s(_vm.$t('name')))]), _c('th', [_vm._v(_vm._s(_vm.$t('image')))])]), _vm._l(_vm.consultantQualifications.slice(0, 20), function (certificates, index) {
    return _c('tr', {
      key: 'certificates-' + index
    }, [_c('td', [_c('a', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.remove_certificates_modal",
        modifiers: {
          "remove_certificates_modal": true
        }
      }],
      staticClass: "btn-box-tool mr-2",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.setCurrentEditCertificates(certificates);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "times"
      }
    })], 1), _c('a', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.edit_certificates_modal",
        modifiers: {
          "edit_certificates_modal": true
        }
      }],
      staticClass: "btn-box-tool",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.setCurrentEditCertificates(certificates);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "pen"
      }
    })], 1)]), _c('td', [_vm._v(_vm._s(_vm.$t(certificates.type)))]), _c('td', [_vm._v(_vm._s((certificates || {}).name))]), _c('td', [_c('a', {
      attrs: {
        "href": certificates.cert_image_path,
        "target": "_blank"
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "image"
      }
    })], 1)])]);
  })], 2)]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.edit_certificates_modal",
      modifiers: {
        "edit_certificates_modal": true
      }
    }],
    staticClass: "btn btn-outline-primary btn-block",
    on: {
      "click": function ($event) {
        return _vm.setCurrentEditCertificates({});
      }
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.$t("add_qualification")))])]), _c('edit-certificates-modal', {
    key: 'edit-' + _vm.currentEditCertificates.qualification_id,
    attrs: {
      "certificates": _vm.currentEditCertificates
    }
  }), _c('remove-certificates-modal', {
    key: 'remove-' + _vm.currentEditCertificates.qualification_id,
    attrs: {
      "certificates": _vm.currentEditCertificates
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }