const deviceLang = {
  messages: {
    tw: {
      'my_devices': '我的設備',
      'operations	': '操作',
      'type': '類型',
      'device': '設備',
      'os': '作業系統',
      'browser': '瀏覽器',
      'add_device': '新增設備',
    },
    en: {
      'my_devices': 'My Devices',
      'operations': 'Operations',
      'type': 'Type',
      'device': 'Device',
      'os': 'OS',
      'browser': 'Browser',
      'add_device': 'Add Device',
    },
  },
};

export {
  deviceLang
};
