export default {
  messages: {
    tw: {
      remove_device: '刪除帳戶',
      remove_confirm_mes: '確定要刪除設備',
      remove_up_success: '帳戶刪除成功',
      remove_up_fail: '帳戶刪除失敗',
    },
    en: {
      remove_device: 'Remove Payment',
      remove_confirm_mes: 'Are you sure you want to remove ',
      remove_up_success: 'Remove payment successfully',
      remove_up_fail: 'Remove payment failed',
    },
  },
};
