<template lang="pug">
  b-modal#remove_device_modal(
    :cancel-title="'Cancle'"
    :ok-title="'OK'"
    :title="$t('remove_device')"
    effect="fade"
    no-close-on-backdrop
    @ok="submit()"
    )
    span {{ $t('remove_confirm_mes') }} &emsp;"{{ device.brand }}&ensp;{{ device.model }}" ?
</template>
<script>
import LioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import i18n from './lang';

export default {
  name: 'RemoveDevice',

  i18n,

  props: {
    // eslint-disable-next-line
    device: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    async submit(){
      try {
        const removeResponse = await LioshutanApi.consultant.removeConsultantDevice({ deviceId: this.device.device_id });
        if (removeResponse.data.message === 'OK'){
          alertMessage(this.$t('success'), 'success', this.$t('remove_up_success'));
          this.getDeviceData();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', this.$t('remove_up_fail'));
      }
    },

    async getDeviceData(){
      // 取得資料並用vuex管理同page資料 (1.方便子層直接取得資料 2.編輯及新增後資料刷新)
      const consultantDevicesRes = await LioshutanApi.consultant.getConsultantDevices();
      this.$store.dispatch('consultant/setDevicesDatas', consultantDevicesRes.data.data);
    },
  },
};

</script>
