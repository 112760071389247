var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.vuecropperNotice)
    }
  })])], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "cut"
  }, [_c('vue-cropper', {
    ref: "cropper",
    attrs: {
      "img": _vm.cropperOption.img,
      "outputsize": 1,
      "outputtype": _vm.cropperOption.outputType,
      "can-move": _vm.cropperOption.canMove,
      "can-move-box": _vm.cropperOption.canMoveBox,
      "fixed-box": _vm.cropperOption.fixedBox,
      "original": _vm.cropperOption.original,
      "auto-crop": true,
      "auto-crop-width": _vm.cropperOption.autoCropWidth,
      "auto-crop-height": _vm.cropperOption.autoCropHeight,
      "center-box": _vm.cropperOption.centerBox
    },
    on: {
      "real-time": _vm.realTimeImage
    }
  })], 1)]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('p', {
    staticClass: "text-center preview-text"
  }, [_vm._v("P r e v i e w")]), _c('div', {
    staticClass: "show-preview",
    attrs: {
      "id": "show-preview"
    }
  }, [_c('img', {
    style: _vm.previews.img,
    attrs: {
      "src": _vm.previews.url
    }
  })])])]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('b-button', {
    attrs: {
      "variant": _vm.confirm ? 'success' : 'outline-danger',
      "disabled": _vm.confirm
    },
    on: {
      "click": _vm.handlePictureImagetoFile
    }
  }, [!_vm.confirm ? _c('span', [_vm._v(_vm._s(_vm.$t('confirm')))]) : _vm.confirm ? _c('span', [_c('font-awesome-icon', {
    staticClass: "text-light",
    attrs: {
      "icon": "check"
    }
  })], 1) : _vm._e()]), _vm.confirm ? _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        _vm.confirm = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('reset')))]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }