<template lang="pug">
  b-modal#edit_payment_modal(
    :cancel-title="$t('cancel')"
    :ok-title="$t('ok')"
    :title="isAddMode ? $t('add_payment') : $t('edit_payment')"
    effect="fade"
    no-close-on-backdrop
    @ok="submit"
    )
    b-form-group(horizontal='' :label-cols='6' breakpoint='sm' :label="$t('payment_method')" label-for='ddlPayment')
      b-form-select#ddlPayment(v-model="paymentData.payment" name='payment' :options='payment_options')
    taiwan-bank(v-if="paymentData.payment === 'taiwan_bank'" ref="taiwan_bank" :isAddMode="isAddMode" :key="'taiwan-bank-' + paymentData.id")
    paypal(v-else-if="paymentData.payment === 'paypal'" ref="paypal" :isAddMode="isAddMode" :key="'paypal' + paymentData.id")
</template>
<script>
import { mapState } from 'vuex';
import paypal from './paypal';
import taiwanBank from './taiwanBank';
import lioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';
import { editPaymentLang } from './lang';
export default {
  name: 'EditPayment',

  components: {
    paypal,
    taiwanBank,
  },

  i18n: editPaymentLang,

  data() {
    return {
      isAddMode: true,

      payment_options: [
        { value: 'taiwan_bank', text: this.$t('taiwan_bank') },
        { value: 'paypal', text: this.$t('paypal') },
      ],

      paymentData: {
        payment: 'taiwan_bank',
        eb: 1, // 新增修改都為:1
      },
    };
  },

  computed: {
    ...mapState({
      payment: state => state.consultant.currentPaymentDatas,
    }),
  },

  watch: {
    // 若為編輯則從vuex取得該編輯資料
    payment(value){
      // 判斷 payment.id 是否為編輯 若為新增payment則不會有id
      this.isAddMode = !Object.prototype.hasOwnProperty.call(value, 'id');
      this.setDefaultPaymentData();
    },
  },

  mounted() {
    this.setDefaultPaymentData();
  },

  methods: {
    submit(){
      if (this.isAddMode){
        this.addPayment();
      } else {
        this.editPayment();
      }
    },

    async addPayment(){
      try {
        // 合併paymentData資料與相對應子元件內填寫資料
        const paymentData = Object.assign(this.paymentData, this.$refs[this.paymentData.payment].data);
        const addResponse = await lioshutanApi.consultant.addConsultantPayment(paymentData);
        if (addResponse.data.message === 'OK'){
          this.editEventSuccess();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', err);
      }
    },

    async editPayment(){
      try {
        // 合併paymentData資料與相對應子元件內填寫資料
        const paymentData = Object.assign(this.paymentData, this.$refs[this.paymentData.payment].data);
        const editResponse = await lioshutanApi.consultant.editConsultantPayment(paymentData);
        if (editResponse.data.message === 'OK'){
          this.editEventSuccess();
        }
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', err);
      }
    },

    editEventSuccess(){
      alertMessage(this.$t('success'), 'success', this.$t('set_up_success'));
      this.getconsultantPaymentDatas();
    },

    setDefaultPaymentData(){
      if (this.isAddMode){
        this.paymentData = {
          payment: 'taiwan_bank',
          eb: 1, // 新增修改都為:1
        };
      } else {
        // 帶入編輯帳戶型式與ID
        this.paymentData.payment = this.payment.payment; // 直接顯示編輯的帳戶型式
        this.paymentData.id = this.payment.id; // 供編輯使用
      }
    },

    async getconsultantPaymentDatas(){
      const consultantPaymentRes = await lioshutanApi.consultant.getConsultantPayment();
      this.$store.dispatch('consultant/setPaymentDatas', consultantPaymentRes.data.data);
    },
  },
};
</script>
