var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "edit_certificates_modal",
      "cancel-title": _vm.$t('cancel'),
      "ok-title": _vm.$t('ok'),
      "title": _vm.isAddMode ? _vm.$t('add_certificates') : _vm.$t('edit_certificates'),
      "effect": "fade",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.submit
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('type'),
      "label-for": "ddlType"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlType",
      "name": "type",
      "options": _vm.types
    },
    model: {
      value: _vm.certificatesData.type,
      callback: function ($$v) {
        _vm.$set(_vm.certificatesData, "type", $$v);
      },
      expression: "certificatesData.type"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('name'),
      "label-for": "txtName"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "txtName",
      "name": "name",
      "placeholder": _vm.$t('please_input') + _vm.$t('name')
    },
    model: {
      value: _vm.certificatesData.name,
      callback: function ($$v) {
        _vm.$set(_vm.certificatesData, "name", $$v);
      },
      expression: "certificatesData.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('image'),
      "label-for": "my_image"
    }
  }, [_c('b-form-file', {
    ref: "certificatesImage",
    attrs: {
      "id": "certificatesImage",
      "name": "image",
      "accept": "image/jpeg, image/jpg, image/png,"
    },
    on: {
      "change": function ($event) {
        return _vm.checkImageFileType($event, 'certificatesImage');
      }
    },
    model: {
      value: _vm.certificatesData.image,
      callback: function ($$v) {
        _vm.$set(_vm.certificatesData, "image", $$v);
      },
      expression: "certificatesData.image"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }